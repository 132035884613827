<template>
  <div id="list-purchase">
    <h2 class="mb-5">Tambah Alamat</h2>
    <div id="wrap" class="col pr-0">
      <div class="card col-12 d-flex justify-content-center">
        <div class="card-body">
          <a-form :form="form" @submit.prevent="submit">
            <a-row :gutter="[16, 16]">
              <a-col :xs="24" :sm="24" :md="12">
                <a-form-item :label="$t('form.first_name')">
                  <a-input v-decorator="['first_name', {
                    rules: [{
                      required: true
                    }],
                  }]" :placeholder="$t('form.first_name')" style="width: 100%" />
                </a-form-item>
              </a-col>

              <a-col :xs="24" :sm="24" :md="12">
                <a-form-item :label="$t('form.last_name')">
                  <a-input v-decorator="['last_name', {
                    rules: [{
                      required: true
                    }],
                  }]" :placeholder="$t('form.last_name')" style="width: 100%" />
                </a-form-item>
              </a-col>

              <a-col :xs="24" :sm="24" :md="12">
                <a-form-item :label="$t('form.phone')">
                  <a-input v-decorator="['phone', {
                    rules: [{
                      required: true,
                      pattern: new RegExp(/[0-9\+]+/g, '')
                    }],
                  }]" :placeholder="$t('form.phone')" style="width: 100%" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="[16, 16]">
              <a-col :xs="24" :sm="24" :md="12">
                <a-form-item :label="'Nama Jalan / Komplek / Lingkungan'">
                  <a-input v-decorator="['address1', {
                    rules: [{
                      required: true
                    }],
                  }]" :placeholder="'Masukkan jalan / Komplek / Lingkungan'" style="width: 100%" />
                </a-form-item>
              </a-col>

              <a-col :xs="24" :sm="24" :md="12">
                <a-form-item :label="'RT/RW'">
                  <a-input v-decorator="['address2']" :placeholder="'Masukkan RT/RW'" style="width: 100%" />
                </a-form-item>
              </a-col>

              <a-col :xs="24" :sm="24" :md="12">
                <a-form-item :label="$t('form.country')">
                  <a-select show-search v-decorator="['country', {
                    rules: [{
                      required: true
                    }],
                    initialValue: initialValueCountry && initialValueCountry,
                  }]" :placeholder="$t('form.country')" @change="handleSelectCountry" style="width: 100%">
                    <a-select-option v-for="(item, index) in countryList" :key="index" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :xs="24" :sm="24" :md="12">
                <a-form-item :label="$t('form.city')">
                  <a-select show-search v-decorator="['city', {
                    rules: [{
                      required: true,
                    }],
                  }]" :placeholder="$t('form.city')" @change="handleSelectCity" style="width: 100%">
                    <a-select-option v-for="(item, index) in cityList" :value="item.label" :key="index">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :xs="24" :sm="24" :md="12">
                <a-form-item :label="$t('form.area')">
                  <a-select show-search v-decorator="['area', {
                    rules: [{
                      required: true,
                    }],
                  }]" :placeholder="$t('form.area')" @change="handleSelectArea" style="width: 100%">
                    <a-select-option v-for="(item, index) in areaList" :value="item.label" :key="index">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :xs="24" :sm="24" :md="12">
                <a-form-item :label="$t('form.postal_code')">
                  <a-input v-decorator="['code_pos', {
                    rules: [{
                      required: true
                    }],
                  }]" :placeholder="$t('form.postal_code')" style="width: 100%" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="[16, 16]" class="d-flex align-items-center flex-row-reverse">
              <a-col :xs="12" :sm="12" :md="4">
                <a-button type="primary" html-type="submit" :disabled="loading" block class="mt-3 btn-submit">
                  <div v-if="!loading"> Simpan </div>
                  <div v-else> <a-icon type="loading" /> </div>
                </a-button>
              </a-col>

              <a-col :xs="12" :sm="12" :md="4">
                <a-button class="mt-3 btn-back" :disabled="loading" @click.prevent="$router.go(-1)">
                  Kembali
                </a-button>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  name: 'AddAddress',
  data() {
    return {
      loading: false,
      form: this.$form.createForm(this),
      countryList: [],
      cityList: [],
      areaList: [],
      initialValueCountry: null,
    }
  },
  methods: {
    submit() {
      this.form.validateFieldsAndScroll((err, values) => {
        if (err) return

        this.addAddress(values)
      })
    },
    addAddress(values) {
      const tempCityProvince = values.city.split(' - ')
      const tempDistrictSubdistrict = values.area.split(' - ')
      const data = {
        first_name: values.first_name,
        last_name: values.last_name,
        phone: values.phone,
        address_line1: values.address1,
        address_line2: values.address2,
        area_id: +this.getAreaId(values.area),
        district: tempDistrictSubdistrict?.length > 0 ? tempDistrictSubdistrict[1] : null,
        sub_district: tempDistrictSubdistrict?.length > 0 ? tempDistrictSubdistrict[0] : null,
        city_id: +this.getCityId(values.city),
        city: tempCityProvince?.length > 0 ? tempCityProvince[0] : null,
        province: tempCityProvince?.length > 0 ? tempCityProvince[1] : null,
        postal_code: values.code_pos,
        country_id: +values.country,
        country: this.getCountryLabel(values.country),
      }

      this.loading = true

      this.$store.dispatch('purchase/ADDSHIPPINGADDRESS', {
        ...data,
        channel_id: this.$store.state.user.user_data.channel_id,
      })
        .then(({ data }) => {
        })
        .catch((err) => {
          console.log(err)
          this.$notification.error({
            message: 'add new address failed',
          })
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
            this.$router.go(-1)
          }, 3000)
        })
    },
    getAreaId(label) {
      const data = this.areaList.find(item => item.label === label)

      return data ? data.value : null
    },
    getCityId(label) {
      const data = this.cityList.find(item => item.label === label)

      return data ? data.value : null
    },
    getCountryLabel(value) {
      const data = this.countryList.find(item => item.value === value)

      return data ? data.label : null
    },
    fetchRegionCountry() {
      this.$store.dispatch('data/GETREGIONCOUNTRY', this.$store.state.app.vendor_hashid)
        .then((data) => {
          this.countryList = data.list

          const getIndonesia = data.list.find(item => item.label.toLowerCase() === 'indonesia')

          if (getIndonesia) {
            this.handleSelectCountry(getIndonesia.value)
            this.initialValueCountry = getIndonesia.value
          }
        })
    },
    fetchAreaList(parentId, param) {
      this.$store.dispatch('data/GETAREALLIST', {
        parentId,
      })
        .then((data) => {
          switch (param) {
            case 'city':
              this.cityList = data.list
              break

            case 'area':
              this.areaList = data.list
              break

            default:
              break
          }
        })
    },
    handleSelectCountry(e) {
      this.fetchAreaList(e, 'city')
    },
    handleSelectCity(e) {
      const key = this.cityList.find(item => item.label === e)
      this.fetchAreaList(key.value, 'area')
    },
    handleSelectArea(e) {
      const findArea = this.areaList.find(item => item.label === e)
      this.form.setFieldsValue({
        code_pos: findArea.postcode,
      })
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.isBottom)
  },
  mounted: function () {
    this.fetchRegionCountry()
  },
}
</script>

<style lang="scss" scoped>
.sub-text {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.btn-edit {
  width: 110px;
  background: #E8F6FE;
  border: 1px solid #015289;
  color: #015289;
  border-radius: 8px;
  padding: 7px 0;
  font-size: 14px;

  &:hover {
    background: #cfeafa;
    color: #015289;
  }
}

.btn-delete {
  width: 110px;
  background: #FEE8E8;
  border: 1px solid #FF0000;
  color: #FF0000;
  border-radius: 8px;
  padding: 7px 0;
  font-size: 14px;

  &:hover {
    background: #f7d4d4;
    color: #FF0000;
  }
}

.text-name {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.text-address {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.btn-submit {
  width: 100%;
  background: var(--biz-brand-button);
  color: #FFFFFF;
  border: 1px solid var(--biz-brand-button);
}

.btn-back {
  width: 100%;
  color: var(--biz-brand-button);
  border: 1px solid var(--biz-brand-button);
}
</style>
