var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"list-purchase"}},[_c('h2',{staticClass:"mb-5"},[_vm._v("Tambah Alamat")]),_c('div',{staticClass:"col pr-0",attrs:{"id":"wrap"}},[_c('div',{staticClass:"card col-12 d-flex justify-content-center"},[_c('div',{staticClass:"card-body"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('a-row',{attrs:{"gutter":[16, 16]}},[_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('form.first_name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['first_name', {
                  rules: [{
                    required: true
                  }],
                }]),expression:"['first_name', {\n                  rules: [{\n                    required: true\n                  }],\n                }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.$t('form.first_name')}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('form.last_name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['last_name', {
                  rules: [{
                    required: true
                  }],
                }]),expression:"['last_name', {\n                  rules: [{\n                    required: true\n                  }],\n                }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.$t('form.last_name')}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('form.phone')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone', {
                  rules: [{
                    required: true,
                    pattern: new RegExp(/[0-9\+]+/g, '')
                  }],
                }]),expression:"['phone', {\n                  rules: [{\n                    required: true,\n                    pattern: new RegExp(/[0-9\\+]+/g, '')\n                  }],\n                }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.$t('form.phone')}})],1)],1)],1),_c('a-row',{attrs:{"gutter":[16, 16]}},[_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',{attrs:{"label":'Nama Jalan / Komplek / Lingkungan'}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['address1', {
                  rules: [{
                    required: true
                  }],
                }]),expression:"['address1', {\n                  rules: [{\n                    required: true\n                  }],\n                }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":'Masukkan jalan / Komplek / Lingkungan'}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',{attrs:{"label":'RT/RW'}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['address2']),expression:"['address2']"}],staticStyle:{"width":"100%"},attrs:{"placeholder":'Masukkan RT/RW'}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('form.country')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['country', {
                  rules: [{
                    required: true
                  }],
                  initialValue: _vm.initialValueCountry && _vm.initialValueCountry,
                }]),expression:"['country', {\n                  rules: [{\n                    required: true\n                  }],\n                  initialValue: initialValueCountry && initialValueCountry,\n                }]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","placeholder":_vm.$t('form.country')},on:{"change":_vm.handleSelectCountry}},_vm._l((_vm.countryList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('form.city')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['city', {
                  rules: [{
                    required: true,
                  }],
                }]),expression:"['city', {\n                  rules: [{\n                    required: true,\n                  }],\n                }]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","placeholder":_vm.$t('form.city')},on:{"change":_vm.handleSelectCity}},_vm._l((_vm.cityList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.label}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('form.area')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['area', {
                  rules: [{
                    required: true,
                  }],
                }]),expression:"['area', {\n                  rules: [{\n                    required: true,\n                  }],\n                }]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","placeholder":_vm.$t('form.area')},on:{"change":_vm.handleSelectArea}},_vm._l((_vm.areaList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.label}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('form.postal_code')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['code_pos', {
                  rules: [{
                    required: true
                  }],
                }]),expression:"['code_pos', {\n                  rules: [{\n                    required: true\n                  }],\n                }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.$t('form.postal_code')}})],1)],1)],1),_c('a-row',{staticClass:"d-flex align-items-center flex-row-reverse",attrs:{"gutter":[16, 16]}},[_c('a-col',{attrs:{"xs":12,"sm":12,"md":4}},[_c('a-button',{staticClass:"mt-3 btn-submit",attrs:{"type":"primary","html-type":"submit","disabled":_vm.loading,"block":""}},[(!_vm.loading)?_c('div',[_vm._v(" Simpan ")]):_c('div',[_c('a-icon',{attrs:{"type":"loading"}})],1)])],1),_c('a-col',{attrs:{"xs":12,"sm":12,"md":4}},[_c('a-button',{staticClass:"mt-3 btn-back",attrs:{"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.$router.go(-1)}}},[_vm._v(" Kembali ")])],1)],1)],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }